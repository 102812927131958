import $ from "jquery";
const PATH = "api:///v6/reward-suppliers";
const list = (data) => $.ajax({
    method: "GET",
    url: `${PATH}`,
    data,
});
const listBuilt = (data) => $.ajax({
    method: "GET",
    url: `${PATH}/built`,
    data,
});
const listDisplayTypes = () => $.ajax({
    method: "GET",
    url: `${PATH}/display-types`,
});
export default {
    list,
    listDisplayTypes,
    listBuilt,
};
