import Session from "Session";
export class CampaignComponentAsset {
    constructor(campaignComponentAssetResponse, context) {
        Object.assign(this, campaignComponentAssetResponse);
        this.context = context;
    }
    getContentUrl() {
        return Session.getInstance().ajaxPrefilter.processUrl(`api:///v2/campaigns/${this.context.campaignId}/components/${this.context.componentId}/assets/${this.id}/content`);
    }
    getFileExtension() {
        const matched = this.filename.match(/\.[0-9a-z]+$/i);
        if (matched === null) {
            return null;
        }
        else {
            return matched[0].replace(".", "");
        }
    }
}
