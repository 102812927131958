import Backbone from "backbone";
import $ from "jquery";

const AudienceModel = Backbone.Model.extend({
    urlRoot: "/api/v1/audiences",

    getStats() {
        const deferred = $.Deferred();
        Backbone.ajax({
            url: `${this.url()}/stats`,
            type: "GET",
            dataType: "json",
        }).done((data) => deferred.resolve(data));
        return deferred;
    },
});

AudienceModel.create = function () {
    return new AudienceModel();
};

export default AudienceModel;
