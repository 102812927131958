export const DEFAULT_OPTIONS = [
    { displayName: "Immediately", value: 0 },
    { displayName: "In 15 minutes", value: 900000 },
    { displayName: "In 30 minutes", value: 1800000 },
    { displayName: "In 1 hour", value: 3600000 },
    { displayName: "The next day", value: 86400000 },
    { displayName: "After a month", value: 2592000000 },
];
export const options = [
    { displayName: "Minutes", value: 60000 },
    { displayName: "Hours", value: 3600000 },
    { displayName: "Days", value: 86400000 },
    { displayName: "Weeks", value: 604800000 },
    { displayName: "Months", value: 2592000000 },
    { displayName: "Years", value: 31536000000 },
];
export const formattedPeriod = (milliseconds) => {
    const millisecondsPerMinute = 60 * 1000;
    const millisecondsPerHour = 60 * millisecondsPerMinute;
    const millisecondsPerDay = 24 * millisecondsPerHour;
    const millisecondsPerWeek = 7 * millisecondsPerDay;
    const millisecondsPerMonth = 30 * millisecondsPerDay;
    const millisecondsPerYear = 365 * millisecondsPerDay;
    const years = Math.floor(milliseconds / millisecondsPerYear);
    const remainingAfterYears = milliseconds % millisecondsPerYear;
    const months = Math.floor(remainingAfterYears / millisecondsPerMonth);
    const remainingAfterMonths = remainingAfterYears % millisecondsPerMonth;
    const weeks = Math.floor(remainingAfterMonths / millisecondsPerWeek);
    const remainingAfterWeeks = remainingAfterMonths % millisecondsPerWeek;
    const days = Math.floor(remainingAfterWeeks / millisecondsPerDay);
    const remainingAfterDays = remainingAfterWeeks % millisecondsPerDay;
    const hours = Math.floor(remainingAfterDays / millisecondsPerHour);
    const remainingAfterHours = remainingAfterDays % millisecondsPerHour;
    const minutes = Math.floor(remainingAfterHours / millisecondsPerMinute);
    const remainingAfterMinutes = remainingAfterHours % millisecondsPerMinute;
    const seconds = Math.floor(remainingAfterMinutes / 1000);
    const remainingMilliseconds = remainingAfterMinutes % 1000;
    const formattedParts = [];
    if (years >= 1) {
        formattedParts.push(`${years} year${years > 1 ? "s" : ""}`);
    }
    if (months >= 1) {
        formattedParts.push(`${months} month${months > 1 ? "s" : ""}`);
    }
    if (weeks >= 1) {
        formattedParts.push(`${weeks} week${weeks > 1 ? "s" : ""}`);
    }
    if (days >= 1) {
        formattedParts.push(`${days} day${days > 1 ? "s" : ""}`);
    }
    if (hours >= 1) {
        formattedParts.push(`${hours} hour${hours > 1 ? "s" : ""}`);
    }
    if (minutes >= 1) {
        formattedParts.push(`${minutes} minute${minutes > 1 ? "s" : ""}`);
    }
    if (seconds >= 1) {
        formattedParts.push(`${seconds} second${seconds > 1 ? "s" : ""}`);
    }
    if (remainingMilliseconds >= 1) {
        formattedParts.push(`${remainingMilliseconds} millisecond${remainingMilliseconds > 1 ? "s" : ""}`);
    }
    return formattedParts.join(" ");
};
