import Strings from "src/shared/Strings";
import RewardSupplier from "./RewardSupplier";
class CustomRewardsRewardSupplier extends RewardSupplier {
    constructor(rewardSupplierResponse) {
        super(rewardSupplierResponse);
    }
    getIconClass() {
        return "fas fa-wallet";
    }
    getDetailLink() {
        return `${this.getTypeLink()}/view/#${this.id}`;
    }
    getTypeLink() {
        if (this.isIntegrationRewardSupplier()) {
            return `/account-rewards/${Strings.encodeLabel(this.display_type)}`;
        }
        return "/account-rewards/custom-rewards";
    }
    getTypeDisplayName() {
        if (this.isIntegrationRewardSupplier()) {
            return `${Strings.capitalize(this.display_type)}`;
        }
        return "Custom Rewards";
    }
}
export default CustomRewardsRewardSupplier;
