import $ from "jquery";
const PATH = "api:///v2/campaigns";
const list = (campaignId) => $.ajax({
    method: "GET",
    url: `${PATH}/${campaignId}/incentive/reward-rules`,
});
const get = (campaignId, id) => $.ajax({
    method: "GET",
    url: `${PATH}/${campaignId}/incentive/reward-rules/${id}`,
});
export default {
    list,
    get,
};
