import ActionAudienceOperation from "./ActionAudienceOperation";
import ModificationAudienceOperation from "./ModificationAudienceOperation";
export const createAudienceOperation = (operationResponse) => {
    const { type } = operationResponse;
    const typeToClassMap = {
        ACTION: ActionAudienceOperation,
        ADD: ModificationAudienceOperation,
        REMOVE: ModificationAudienceOperation,
        REPLACE: ModificationAudienceOperation,
    };
    const OperationClass = typeToClassMap[type];
    if (!OperationClass) {
        throw new Error(`Unknown audience operation type: ${type}`);
    }
    return new OperationClass(operationResponse);
};
