import FileAssetAudienceOperationDataSource from "./FileAssetAudienceOperationDataSource";
import PersonListAudienceOperationDataSource from "./PersonListAudienceOperationDataSource";
import ReportAudienceOperationDataSource from "./ReportAudienceOperationDataSource";
export const createDataSource = (operationDataSource) => {
    const { type } = operationDataSource;
    switch (type) {
        case FileAssetAudienceOperationDataSource.TYPE:
            return new FileAssetAudienceOperationDataSource(operationDataSource);
        case PersonListAudienceOperationDataSource.TYPE:
            return new PersonListAudienceOperationDataSource(operationDataSource);
        case ReportAudienceOperationDataSource.TYPE:
            return new ReportAudienceOperationDataSource(operationDataSource);
        default:
            throw new Error(`Unknown operation data source type: ${type}`);
    }
};
