import $ from "jquery";
const PATH = "api:///v1/audiences";
const get = (audienceId) => $.ajax({
    method: "GET",
    url: `${PATH}/${audienceId}`,
});
const list = (data = {}) => $.ajax({
    method: "GET",
    url: `${PATH}/built`,
    data,
});
const update = (audienceId, data) => $.ajax({
    method: "PUT",
    url: `${PATH}/${audienceId}`,
    contentType: "application/json",
    data: JSON.stringify(data),
});
const create = (data) => $.ajax({
    method: "POST",
    url: PATH,
    contentType: "application/json",
    data: JSON.stringify(data),
});
const archive = (audienceId) => $.ajax({
    method: "DELETE",
    url: `${PATH}/${audienceId}`,
});
const getStats = (audienceId) => $.ajax({
    method: "GET",
    url: `${PATH}/${audienceId}/stats`,
});
const getMembers = (audienceId, data) => $.ajax({
    method: "GET",
    url: `${PATH}/${audienceId}/members/view/details`,
    data,
});
const downloadMembers = (audienceId, format, data) => $.ajax({
    method: "GET",
    url: `${PATH}/${audienceId}/members/download.${format}`,
    data,
});
export default {
    get,
    list,
    update,
    create,
    archive,
    getStats,
    getMembers,
    downloadMembers,
};
