var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isEmpty } from "underscore";
import { computed } from "vue";
import { useAudienceStore } from "..";
import { useAsyncPrimer } from "../../common/async-primer";
export const useAudiences = () => {
    const audienceStore = useAudienceStore();
    const primer = useAsyncPrimer(() => __awaiter(void 0, void 0, void 0, function* () {
        if (isEmpty(audienceStore.listAudiences())) {
            yield audienceStore.fetchAudiences();
        }
    }), "useAudiences");
    const audiences = computed(() => {
        return audienceStore.listAudiences();
    });
    return {
        audiences,
        primer,
    };
};
