import { MODIFICATION_TYPES } from "../BaseAudienceOperation";
export const PROGRESS_STATES = [
    "PREPARING",
    "BUILDING",
    "VALIDATING",
];
export const NON_AVAILABLE_STATES = [
    "CANCELED",
    "EXPIRED",
    "FAILED",
];
export default class BaseAudienceOperationStateResponse {
    constructor(stateResponse) {
        Object.assign(this, stateResponse);
    }
    isInProgress() {
        return PROGRESS_STATES.includes(this.state);
    }
    isFailed() {
        return this.state === "FAILED";
    }
    isCanceled() {
        return this.state === "CANCELED";
    }
    isNotAvailable() {
        return NON_AVAILABLE_STATES.includes(this.state);
    }
    isExpired() {
        return this.state === "EXPIRED";
    }
    static isModificationAudienceOperationState(audienceOperationState) {
        return MODIFICATION_TYPES.includes(audienceOperationState.type);
    }
    static isActionAudienceOperationState(audienceOperationState) {
        return audienceOperationState.type === "ACTION";
    }
}
