import RewardSupplier from "./RewardSupplier";
class PaypalPayoutsRewardSupplier extends RewardSupplier {
    constructor(rewardSupplierResponse) {
        super(rewardSupplierResponse);
    }
    getIconClass() {
        return "fab fa-paypal";
    }
    getDetailLink() {
        return `${this.getTypeLink()}/view/#${this.id}`;
    }
    getTypeLink() {
        return "/account-rewards/paypal-payouts";
    }
    getTypeDisplayName() {
        return "Paypal Payouts";
    }
}
export default PaypalPayoutsRewardSupplier;
