import Session from "js/Session";
import Uri from "jsuri";
import AudienceModel from "src/shared/audiences/AudienceModel";
const DOWNLOAD_URL_BASE = "api:///v1/audiences";
const FORMATS = ["json", "csv", "xlsx"];
export default class Audience {
    constructor(response) {
        Object.assign(this, response);
    }
    convertToBackboneModel() {
        const data = JSON.parse(JSON.stringify(this));
        return new AudienceModel(data);
    }
    getDownloadUrls() {
        const downloadUri = Session.getInstance().ajaxPrefilter.processUrl(`${DOWNLOAD_URL_BASE}/${this.id}/members/download`);
        const accessToken = Session.getInstance().getAccessToken();
        return FORMATS.map((format) => {
            const uri = new Uri(`${downloadUri}.${format.toLowerCase()}`).addQueryParam("access_token", accessToken);
            return {
                format,
                url: uri.toString(),
            };
        });
    }
}
