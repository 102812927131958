import RewardSupplier from "./RewardSupplier";
class SalesforceCouponsRewardSupplier extends RewardSupplier {
    constructor(rewardSupplierResponse) {
        super(rewardSupplierResponse);
    }
    getIconClass() {
        return "fas fa-cloud";
    }
    getDetailLink() {
        return `${this.getTypeLink()}/view/#${this.id}`;
    }
    getTypeLink() {
        return "/account-rewards/SFCC";
    }
    getTypeDisplayName() {
        return "SFCC Coupons";
    }
}
export default SalesforceCouponsRewardSupplier;
