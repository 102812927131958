var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMemoize } from "@vueuse/core";
import { defineStore } from "pinia";
import CampaignComponentAssetEndpoints from "src/shared/api/campaign/component/CampaignComponentAssetEndpoints";
import { toPromise } from "src/shared/api/promise";
import _ from "underscore";
import { reactive } from "vue";
import { CampaignComponentAsset } from "./model/CampaignComponentAsset";
import { getErrorMessage } from "../common/Exception";
const convertMutableToRequest = (mutable) => {
    return Object.assign(Object.assign(Object.assign({}, (mutable.name && {
        name: mutable.name,
    })), (mutable.description && {
        description: mutable.description,
    })), (mutable.tags !== undefined && {
        tags: mutable.tags,
    }));
};
export const useCampaignComponentAssetStore = defineStore("campaignComponentAssetStore", () => {
    const state = reactive({
        assets: {},
    });
    const provideAssets = useMemoize((campaignId, componentId) => {
        state.assets[campaignId] = {};
        state.assets[campaignId][componentId] = {};
        return state.assets[campaignId][componentId];
    });
    const setAssets = (campaignId, componentId, campaignComponentAssetResponses) => {
        const assets = provideAssets(campaignId, componentId);
        return _.map(campaignComponentAssetResponses, (response) => {
            const asset = new CampaignComponentAsset(response, {
                campaignId,
                componentId,
            });
            assets[asset.id] = asset;
            return asset;
        });
    };
    const fetchAssets = (campaignId, componentId) => __awaiter(void 0, void 0, void 0, function* () {
        const assetResponses = yield toPromise(CampaignComponentAssetEndpoints.list(campaignId, componentId));
        return setAssets(campaignId, componentId, assetResponses);
    });
    const fetchAsset = (campaignId, componentId, assetId) => __awaiter(void 0, void 0, void 0, function* () {
        const assetResponse = yield toPromise(CampaignComponentAssetEndpoints.get(campaignId, componentId, assetId));
        setAssets(campaignId, componentId, [assetResponse]);
    });
    const createAsset = (campaignId, componentId, update, file) => __awaiter(void 0, void 0, void 0, function* () {
        const formData = new FormData();
        if (update) {
            formData.append("asset", JSON.stringify(convertMutableToRequest(update)));
        }
        if (file) {
            formData.append("file", file, file.name);
        }
        const assetResponse = yield toPromise(CampaignComponentAssetEndpoints.create(campaignId, componentId, formData));
        const [newAsset] = setAssets(campaignId, componentId, [
            assetResponse,
        ]);
        return newAsset;
    });
    const updateAsset = (campaignId, componentId, assetId, update, file) => __awaiter(void 0, void 0, void 0, function* () {
        const formData = new FormData();
        if (update) {
            console.log(convertMutableToRequest(update));
            formData.append("asset", JSON.stringify(convertMutableToRequest(update)));
        }
        if (file) {
            formData.append("file", file, file.name);
        }
        const assetResponse = yield toPromise(CampaignComponentAssetEndpoints.update(campaignId, componentId, assetId, formData));
        setAssets(campaignId, componentId, [assetResponse]);
    });
    const deleteAsset = (campaignId, componentId, assetId) => __awaiter(void 0, void 0, void 0, function* () {
        yield toPromise(CampaignComponentAssetEndpoints.deleteAsset(campaignId, componentId, assetId));
        const assets = provideAssets(campaignId, componentId);
        delete assets[assetId];
    });
    const getAssets = (campaignId, componentId) => {
        const assets = provideAssets(campaignId, componentId);
        return _.values(assets);
    };
    const getAssetByName = (campaignId, componentId, assetName) => {
        return _.find(getAssets(campaignId, componentId), (asset) => asset.name === assetName);
    };
    const saveFile = (campaignId, componentId, file) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            return yield createAsset(campaignId, componentId, {}, file);
        }
        catch (e) {
            if (e.code === "campaign_component_asset_name_duplicated") {
                const existingAssetName = e.parameters.name;
                const asset = getAssetByName(campaignId, componentId, existingAssetName);
                if (asset !== undefined) {
                    return asset;
                }
                else {
                    throw new Error(getErrorMessage(e));
                }
            }
            else {
                throw e;
            }
        }
    });
    return {
        state,
        fetchAssets,
        fetchAsset,
        createAsset,
        updateAsset,
        deleteAsset,
        getAssets,
        getAssetByName,
        saveFile,
    };
});
