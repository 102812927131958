import ActionAudienceOperationDebugResponse from "./ActionAudienceOperationDebugResponse";
import ModificationAudienceOperationDebugResponse from "./ModificationAudienceOperationDebugResponse";
export const createAudienceOperationDebug = (debugResponse) => {
    const { type } = debugResponse;
    const typeToClassMap = {
        ACTION: ActionAudienceOperationDebugResponse,
        ADD: ModificationAudienceOperationDebugResponse,
        REMOVE: ModificationAudienceOperationDebugResponse,
        REPLACE: ModificationAudienceOperationDebugResponse,
    };
    const OperationDebugClass = typeToClassMap[type];
    if (!OperationDebugClass) {
        throw new Error(`Unknown audience operation debug type: ${type}`);
    }
    return new OperationDebugClass(debugResponse);
};
