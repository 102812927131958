export const MODIFICATION_TYPES = [
    "ADD",
    "REMOVE",
    "REPLACE",
];
class BaseAudienceOperation {
    constructor(operation) {
        Object.assign(this, operation);
    }
    static isModificationAudienceOperation(operation) {
        return MODIFICATION_TYPES.includes(operation.type);
    }
    static isActionAudienceOperation(operation) {
        return operation.type === "ACTION";
    }
}
export default BaseAudienceOperation;
