var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _ from "underscore";
import { defineStore } from "pinia";
import { reactive } from "vue";
import RewardSupplierEndpoints from "src/shared/api/reward/supplier/RewardSupplierEndpoints";
import { toPromise } from "src/shared/api/promise";
import { createRewardSupplierClass } from "./model/factory";
const convertToParameters = (parameters) => {
    var _a;
    return Object.assign(Object.assign(Object.assign(Object.assign({}, (parameters.limit && {
        limit: String(parameters.limit),
    })), (parameters.offset && {
        offset: String(parameters.offset),
    })), (parameters.include_archived === undefined && {
        include_archived: false,
    })), { include_disabled: (_a = parameters.include_disabled) !== null && _a !== void 0 ? _a : false });
};
export const useRewardSupplierStore = defineStore("rewardSupplierStore", () => {
    const state = reactive({
        rewardSuppliers: {},
        rewardSupplierDisplayTypes: [],
    });
    const fetchBuiltRewardSuppliers = (parameters) => __awaiter(void 0, void 0, void 0, function* () {
        const rewardSuppliersResponse = yield toPromise(RewardSupplierEndpoints.listBuilt(convertToParameters(parameters)));
        state.rewardSuppliers = {};
        setRewardSuppliers(rewardSuppliersResponse);
    });
    const fetchRewardSuppliers = (parameters) => __awaiter(void 0, void 0, void 0, function* () {
        const rewardSuppliersResponse = yield toPromise(RewardSupplierEndpoints.list(convertToParameters(parameters)));
        state.rewardSuppliers = {};
        setRewardSuppliers(rewardSuppliersResponse);
    });
    const fetchRewardSupplierDisplayTypes = () => __awaiter(void 0, void 0, void 0, function* () {
        const rewardSupplierDisplayTypesResponse = yield toPromise(RewardSupplierEndpoints.listDisplayTypes());
        state.rewardSupplierDisplayTypes = rewardSupplierDisplayTypesResponse;
    });
    const setRewardSuppliers = (rewardSuppliersResponse) => {
        _.each(rewardSuppliersResponse, (response) => {
            const rewardSupplier = createRewardSupplierClass(response);
            state.rewardSuppliers[response.id] = rewardSupplier;
        });
    };
    const listAllRewardSuppliers = () => {
        return _.values(state.rewardSuppliers);
    };
    const listAllRewardSupplierDisplayTypes = () => {
        return state.rewardSupplierDisplayTypes;
    };
    const getRewardSupplier = (id) => {
        return state.rewardSuppliers[id];
    };
    return {
        fetchBuiltRewardSuppliers,
        listAllRewardSuppliers,
        getRewardSupplier,
        fetchRewardSupplierDisplayTypes,
        fetchRewardSuppliers,
        listAllRewardSupplierDisplayTypes,
    };
});
