var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineStore } from "pinia";
import { toPromise } from "src/shared/api/promise";
import _ from "underscore";
import { reactive } from "vue";
import CampaignComponentVariableEndpoints from "src/shared/api/campaign/component/variable/CampaignComponentVariableEndpoints";
import { useMemoize } from "@vueuse/core";
import CampaignComponentVariable from "./model/CampaignComponentVariable";
import { campaignComponentChangeEvent } from "../../event-bus/campaign-component";
import { getCampaignOperationQueue } from "../../control-flow/campaign-operation-queue";
import CampaignComponentEndpoints from "src/shared/api/campaign/component/CampaignComponentEndpoints";
import BatchVariableEndpoints from "src/shared/api/campaign/component/variable/batch/BatchVariableEndpoints";
import { isVariableQualifiedForAssetOperation, pullDownSourceVariableImageAssetsAndCalculateVariableValues, } from "./component-variable-asset-service";
const convertToVariableUpdateRequest = (variable) => {
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (variable.display_name && { display_name: variable.display_name })), (variable.type && { type: variable.type })), (variable.values && { values: variable.values })), (variable.source && { source: variable.source })), (variable.description && { description: variable.description })), (variable.tags && { tags: variable.tags }));
};
const convertToVariableCreateRequest = (variable) => {
    return Object.assign({ name: variable.name, type: variable.type, values: variable.values, source: variable.source, description: variable.description, tags: variable.tags }, (variable.display_name && { display_name: variable.display_name }));
};
export const useCampaignComponentVariableStore = defineStore("campaignComponentVariableStore", () => {
    const state = reactive({});
    const provideVariables = useMemoize((campaignId, componentId) => {
        state[campaignId] = {};
        state[campaignId][componentId] = {};
        return state[campaignId][componentId];
    });
    const setVariables = (campaignId, componentId, campaignComponentVariableResponses) => {
        const variables = provideVariables(campaignId, componentId);
        _.each(campaignComponentVariableResponses, (response) => {
            const variable = new CampaignComponentVariable(response, {
                campaignId,
                componentId,
            });
            variables[variable.name] = variable;
        });
    };
    const fetchVariables = (campaignId, componentId) => __awaiter(void 0, void 0, void 0, function* () {
        const variableResponses = yield toPromise(CampaignComponentVariableEndpoints.list(campaignId, componentId));
        setVariables(campaignId, componentId, variableResponses);
    });
    const fetchVariable = (campaignId, componentId, variableName) => __awaiter(void 0, void 0, void 0, function* () {
        const variableResponse = yield toPromise(CampaignComponentVariableEndpoints.get(campaignId, componentId, variableName));
        setVariables(campaignId, componentId, [variableResponse]);
    });
    const getVariables = (campaignId, componentId) => {
        const variables = provideVariables(campaignId, componentId);
        return _.values(variables);
    };
    const getVariable = (campaignId, componentId, variableName) => {
        const variables = provideVariables(campaignId, componentId);
        return variables[variableName];
    };
    const createVariable = (campaignId, componentId, variable) => {
        return new Promise((resolve, reject) => {
            getCampaignOperationQueue(campaignId).push((done) => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    const request = convertToVariableCreateRequest(variable);
                    const variableResponse = yield toPromise(CampaignComponentVariableEndpoints.create(campaignId, componentId, request));
                    setVariables(campaignId, componentId, [
                        variableResponse,
                    ]);
                    campaignComponentChangeEvent.emit({
                        campaignId,
                        componentId,
                    });
                    resolve();
                }
                catch (error) {
                    reject(error);
                }
                finally {
                    done();
                }
            }));
        });
    };
    const updateVariableV2 = (campaignId, componentId, variableName, variable) => __awaiter(void 0, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            getCampaignOperationQueue(campaignId).push((done) => __awaiter(void 0, void 0, void 0, function* () {
                const request = convertToVariableUpdateRequest(variable);
                try {
                    const response = yield toPromise(CampaignComponentVariableEndpoints.update(campaignId, componentId, variableName, request));
                    setVariables(campaignId, componentId, [response]);
                    campaignComponentChangeEvent.emit({
                        campaignId,
                        componentId,
                    });
                    resolve();
                }
                catch (error) {
                    reject(error);
                }
                finally {
                    done();
                }
            }));
        });
    });
    const inheritValuesFromParent = (campaignId, componentId, variableName) => __awaiter(void 0, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            const currentVariable = getVariable(campaignId, componentId, variableName);
            if (!currentVariable) {
                resolve();
                return;
            }
            if (currentVariable.source === "INHERITED") {
                resolve();
                return;
            }
            getCampaignOperationQueue(campaignId).push((done) => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    const response = yield toPromise(CampaignComponentVariableEndpoints.update(campaignId, componentId, variableName, {
                        source: "INHERITED",
                        type: currentVariable.type,
                    }));
                    setVariables(campaignId, componentId, [response]);
                    campaignComponentChangeEvent.emit({
                        campaignId,
                        componentId,
                    });
                    resolve();
                }
                catch (error) {
                    reject(error);
                }
                finally {
                    done();
                }
            }));
        });
    });
    const useLocalValues = (campaignId, componentId, variableName, values) => __awaiter(void 0, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            const currentVariable = getVariable(campaignId, componentId, variableName);
            if (!currentVariable) {
                resolve();
                return;
            }
            if (currentVariable.source === "LOCAL") {
                resolve();
                return;
            }
            getCampaignOperationQueue(campaignId).push((done) => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    const valuesMixin = {
                        values,
                    };
                    if (isVariableQualifiedForAssetOperation(currentVariable)) {
                        valuesMixin.values =
                            yield pullDownSourceVariableImageAssetsAndCalculateVariableValues(campaignId, componentId, variableName, {
                                getVariable,
                                fetchVariable,
                            });
                    }
                    const response = yield toPromise(CampaignComponentVariableEndpoints.update(campaignId, componentId, variableName, Object.assign(Object.assign({ source: "LOCAL" }, valuesMixin), { type: currentVariable.type })));
                    setVariables(campaignId, componentId, [response]);
                    campaignComponentChangeEvent.emit({
                        campaignId,
                        componentId,
                    });
                    resolve();
                }
                catch (error) {
                    reject(error);
                }
                finally {
                    done();
                }
            }));
        });
    });
    const updateVariablePriority = (campaignId, componentId, variableName, priority) => __awaiter(void 0, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            const currentVariable = getVariable(campaignId, componentId, variableName);
            if (!currentVariable) {
                resolve();
                return;
            }
            getCampaignOperationQueue(campaignId).push((done) => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    const response = yield toPromise(CampaignComponentVariableEndpoints.update(campaignId, componentId, variableName, {
                        priority,
                        type: currentVariable.type,
                    }));
                    setVariables(campaignId, componentId, [response]);
                    campaignComponentChangeEvent.emit({
                        campaignId,
                        componentId,
                    });
                    resolve();
                }
                catch (error) {
                    reject(error);
                }
                finally {
                    done();
                }
            }));
        });
    });
    const deleteVariable = (campaignId, componentId, variableName) => {
        return new Promise((resolve, reject) => {
            getCampaignOperationQueue(campaignId).push((done) => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    yield toPromise(CampaignComponentVariableEndpoints.destroy(campaignId, componentId, variableName));
                    const variables = provideVariables(campaignId, componentId);
                    delete variables[variableName];
                    campaignComponentChangeEvent.emit({
                        campaignId,
                        componentId,
                    });
                    resolve();
                }
                catch (e) {
                    yield fetchVariable(campaignId, componentId, variableName);
                    reject(e);
                }
                finally {
                    done();
                }
            }));
        });
    };
    const saveVariables = (campaignId, componentId) => __awaiter(void 0, void 0, void 0, function* () {
        const variables = getVariables(campaignId, componentId);
        const requests = _.map(variables, (variable) => {
            return {
                name: variable.name,
                type: variable.type,
                description: variable.description,
                tags: variable.tags,
                source: variable.source,
                values: variable.values,
            };
        });
        const componentUpdateRequest = {
            variables: requests,
        };
        const updatedCampaignComponent = yield CampaignComponentEndpoints.update(campaignId, componentId, componentUpdateRequest);
        setVariables(campaignId, componentId, updatedCampaignComponent.variables);
        campaignComponentChangeEvent.emit({
            campaignId,
            componentId,
        });
    });
    const batchUpdateVariables = (campaignId, componentId, variables) => __awaiter(void 0, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            if (variables.length === 0) {
                resolve();
                return;
            }
            getCampaignOperationQueue(campaignId).push((done) => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    const batchUpdateRequest = {
                        variables: {},
                    };
                    _.each(variables, (variable) => {
                        batchUpdateRequest.variables[variable.name] =
                            convertToVariableUpdateRequest(variable);
                    });
                    const batchVariableUpdateResponse = yield toPromise(BatchVariableEndpoints.update(campaignId, componentId, batchUpdateRequest));
                    setVariables(campaignId, componentId, _.values(batchVariableUpdateResponse.variables));
                    campaignComponentChangeEvent.emit({
                        campaignId,
                        componentId,
                    });
                    resolve();
                }
                catch (error) {
                    reject(error);
                }
                finally {
                    done();
                }
            }));
        });
    });
    campaignComponentChangeEvent.on((event) => {
        fetchVariables(event.campaignId, event.componentId);
    });
    return {
        state,
        fetchVariables,
        fetchVariable,
        createVariable,
        updateVariableV2,
        updateVariablePriority,
        batchUpdateVariables,
        deleteVariable,
        getVariable,
        getVariables,
        saveVariables,
        useLocalValues,
        inheritValuesFromParent,
    };
});
