import _ from "underscore";
import { getCampaignComponentVariableDefaultValue } from "./campaign-component-variable-value-defaults";
class CampaignComponentVariableUpdateMutable {
    constructor(variable) {
        this.name = variable.name;
        this.display_name = variable.display_name;
        this.description = variable.description;
        this.type = variable.type;
        this.source = variable.source;
        this.priority = variable.priority;
        if (variable.values) {
            this.values = Object.assign({}, variable.values);
        }
        if (variable.tags) {
            this.tags = [...variable.tags];
        }
    }
    addDimension(dimension) {
        if (!this.type) {
            return;
        }
        if (!this.values) {
            this.values = {};
        }
        const clone = Object.assign({}, this.values);
        clone[dimension] = getCampaignComponentVariableDefaultValue(this.type);
        this.values = clone;
    }
    removeDimension(dimension) {
        if (this.values) {
            delete this.values[dimension];
        }
    }
    getDimensions() {
        return _.keys(this.values);
    }
    isAudienceVariable() {
        return this.name === "havingAnyAudienceId";
    }
    static fromVariable(variable) {
        return new CampaignComponentVariableUpdateMutable({
            name: variable.name,
            display_name: variable.display_name,
            description: variable.description,
            type: variable.type,
            source: variable.source,
            values: Object.assign({}, variable.values),
            tags: [...variable.tags],
            priority: variable.priority,
        });
    }
}
export default CampaignComponentVariableUpdateMutable;
