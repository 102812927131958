var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BrowserFlagCollection } from "src/shared/feature-flag-service";
import { getPropertyName } from "./model/feature-flags";
import Session from "Session";
const createBrowserFlagCollection = (id) => {
    const name = `_feature_flag_${id}`;
    const browserFlags = new BrowserFlagCollection([], {
        name,
    });
    browserFlags._fetchSync();
    return browserFlags;
};
const isOn = (id) => {
    const propertyName = getPropertyName(id);
    const browserFlags = createBrowserFlagCollection(id);
    let value = Session.getInstance().getProperties()[propertyName];
    const flag = browserFlags.first();
    if (flag) {
        value = flag.get("value");
    }
    return value === "ON";
};
const turnOn = (id) => {
    const propertyName = getPropertyName(id);
    const browserFlags = new BrowserFlagCollection([], {
        name: propertyName,
    });
    browserFlags._fetchSync();
    if (browserFlags.isEmpty()) {
        browserFlags.create({
            name: propertyName,
            value: "ON",
        });
    }
    else {
        const model = browserFlags.first();
        model.set("value", "ON");
        model.save();
    }
};
const turnOff = (id) => {
    const propertyName = getPropertyName(id);
    const browserFlags = new BrowserFlagCollection([], {
        name: propertyName,
    });
    if (browserFlags.isEmpty()) {
        browserFlags.create({
            name: propertyName,
            value: "OFF",
        });
    }
    else {
        const model = browserFlags.first();
        model.set("value", "OFF");
        model.save();
    }
};
const toggleFlag = (id) => __awaiter(void 0, void 0, void 0, function* () {
    if (isOn(id)) {
        turnOff(id);
    }
    else {
        turnOn(id);
    }
});
export default {
    isOn,
    toggleFlag,
};
