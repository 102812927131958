import $ from "jquery";
const PATH = "api:///v1/audiences";
const buildURL = (audienceId) => `${PATH}/${audienceId}/operations`;
const list = (audienceId, data) => $.ajax({
    method: "GET",
    url: buildURL(audienceId),
    data,
});
const get = (audienceId, operationId) => $.ajax({
    method: "GET",
    url: `${buildURL(audienceId)}/${operationId}`,
});
const getState = (audienceId, operationId) => $.ajax({
    method: "GET",
    url: `${buildURL(audienceId)}/${operationId}/state`,
});
const cancel = (audienceId, operationId) => $.ajax({
    method: "POST",
    url: `${buildURL(audienceId)}/${operationId}/cancel`,
});
const retry = (audienceId, operationId) => $.ajax({
    method: "POST",
    url: `${buildURL(audienceId)}/${operationId}/retry`,
});
const getDetails = (audienceId, operationId) => $.ajax({
    method: "GET",
    url: `${buildURL(audienceId)}/${operationId}/view/details`,
});
const getDebug = (audienceId, operationId) => $.ajax({
    method: "GET",
    url: `${buildURL(audienceId)}/${operationId}/state/debug`,
});
const create = (audienceId, data) => $.ajax({
    method: "POST",
    url: buildURL(audienceId),
    contentType: "application/json",
    data: JSON.stringify(data),
});
export default {
    list,
    get,
    getState,
    cancel,
    retry,
    getDetails,
    getDebug,
    create,
};
