export const adjustTextareaHeight = (target, focused) => {
    if (!target.value) {
        return;
    }
    if (focused) {
        target.value.style.height = "auto";
        target.value.style.height = `${target.value.scrollHeight}px`;
    }
    else {
        target.value.style.height = "";
    }
};
