import ActionDetailedAudienceOperation from "./ActionDetailedAudienceOperation";
import ModificationDetailedAudienceOperation from "./ModificationDetailedAudienceOperation";
export const createDetailedAudienceOperation = (operationResponse) => {
    const { type } = operationResponse;
    const typeToClassMap = {
        ACTION: ActionDetailedAudienceOperation,
        ADD: ModificationDetailedAudienceOperation,
        REMOVE: ModificationDetailedAudienceOperation,
        REPLACE: ModificationDetailedAudienceOperation,
    };
    const OperationClass = typeToClassMap[type];
    if (!OperationClass) {
        throw new Error(`Unknown audience operation type: ${type}`);
    }
    return new OperationClass(operationResponse);
};
