import Uri from "jsuri";
import Session from "js/Session";
import Strings from "src/shared/Strings";
export default class Person {
    constructor(personResponse) {
        this.person_id = personResponse.id;
        Object.assign(this, personResponse);
    }
    getEmail() {
        return this.email || "Anonymous";
    }
    getName() {
        const firstName = Strings.capitalize(this.first_name);
        const lastName = Strings.capitalize(this.last_name);
        if (firstName && lastName) {
            return `${firstName} ${lastName}`;
        }
        if (firstName) {
            return firstName;
        }
        if (lastName) {
            return lastName;
        }
        return null;
    }
    getHandle() {
        const name = this.getName();
        return name || this.email || "Anonymous";
    }
    getFirstName() {
        return Strings.capitalize(this.first_name) || "";
    }
    getLastName() {
        return Strings.capitalize(this.last_name) || "";
    }
    getPictureUrl() {
        return this.picture_url
            ? new Uri(this.picture_url)
                .addQueryParam("access_token", Session.getInstance().getAccessToken())
                .toString()
            : this.picture_url;
    }
    getStatus() {
        return this.blocked ? "blocked" : "active";
    }
}
