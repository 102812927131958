import RewardSupplier from "./RewardSupplier";
class ManualCouponsRewardSupplier extends RewardSupplier {
    constructor(rewardSupplierResponse) {
        super(rewardSupplierResponse);
    }
    getIconClass() {
        return "fas fa-money-check";
    }
    getDetailLink() {
        return `${this.getTypeLink()}/view/#${this.id}`;
    }
    getTypeLink() {
        return "/account-rewards/coupons";
    }
    getTypeDisplayName() {
        return "Coupons";
    }
}
export default ManualCouponsRewardSupplier;
