import BaseAudienceOperationStateResponse from "./BaseAudienceOperationStateResponse";
export class AudienceOperationResultResponse {
    constructor(response) {
        Object.assign(this, response);
    }
}
class ModificationAudienceOperationStateResponse extends BaseAudienceOperationStateResponse {
    constructor(response) {
        super(response);
        this.result = new AudienceOperationResultResponse(response.result);
    }
}
export default ModificationAudienceOperationStateResponse;
