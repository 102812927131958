import ActionAudienceOperationStateResponse from "./ActionAudienceOperationStateResponse";
import ModificationAudienceOperationStateResponse from "./ModificationAudienceOperationStateResponse";
export const createAudienceOperationState = (stateResponse) => {
    const { type } = stateResponse;
    const typeToClassMap = {
        ACTION: ActionAudienceOperationStateResponse,
        ADD: ModificationAudienceOperationStateResponse,
        REMOVE: ModificationAudienceOperationStateResponse,
        REPLACE: ModificationAudienceOperationStateResponse,
    };
    const OperationClass = typeToClassMap[type];
    if (!OperationClass) {
        throw new Error(`Unknown audience operation state type: ${type}`);
    }
    return new OperationClass(stateResponse);
};
