var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineStore } from "pinia";
import { toPromise } from "src/shared/api/promise";
import { reactive } from "vue";
import CampaignRewardRule from "./model/CampaignRewardRule";
import CampaignRewardRuleEndpoints from "src/shared/api/campaign/incentive/reward/rule/CampaignRewardRuleEndpoints";
export const useCampaignRewardRuleStore = defineStore("campaignRewardRuleStore", () => {
    const state = reactive({
        rewardRules: {},
    });
    const setCampaignRewardRules = (rewardRulesResponses) => {
        return rewardRulesResponses.map((stepResponse) => {
            const rewardRule = new CampaignRewardRule(stepResponse);
            state.rewardRules[rewardRule.id] = rewardRule;
            return rewardRule;
        });
    };
    const fetchCampaignRewardRule = (campaignId, stepId) => __awaiter(void 0, void 0, void 0, function* () {
        const rewardRuleResponse = yield toPromise(CampaignRewardRuleEndpoints.get(campaignId, stepId));
        const [rewardRule] = setCampaignRewardRules([rewardRuleResponse]);
        return rewardRule;
    });
    const getCampaignRewardRule = (campaignId, id) => {
        return state.rewardRules[id];
    };
    return {
        state,
        fetchCampaignRewardRule,
        getCampaignRewardRule,
    };
});
