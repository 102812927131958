import $ from "jquery";
import { encode } from "src/shared/api/request-encoder";
const PATH = "api:///v2/settings/security/keys";
const listClientKeys = (clientKeyFilterRequest) => $.ajax({
    method: "GET",
    url: `${PATH}`,
    data: encode(clientKeyFilterRequest),
});
const listBuilt = (clientKeyFilterRequest) => $.ajax({
    method: "GET",
    url: `${PATH}/built`,
    data: encode(clientKeyFilterRequest),
});
export default {
    listClientKeys,
    listBuilt,
};
