import BaseAudienceOperation from "./BaseAudienceOperation";
import { createDataSource } from "./data/source/factory";
class ModificationAudienceOperation extends BaseAudienceOperation {
    constructor(response) {
        super(response);
        this.data_source = createDataSource(response.data_source);
    }
    isFileAssetDataSource() {
        return this.data_source.type === "FILE_ASSET";
    }
    isReportDataSource() {
        return this.data_source.type === "REPORT";
    }
}
export default ModificationAudienceOperation;
