var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { until } from "@vueuse/core";
import { forEach } from "lodash";
import faro from "src/shared/third-party-integrations/faro";
import { useCampaignComponentAssetStore } from "../../campaign-component-asset";
import { useBuiltComponent, useBuiltComponentVariable, } from "../../component-built/composables";
const getExtensionFromMimeType = (mimeType) => {
    const mimeToExtMap = {
        "image/jpeg": ".jpg",
        "image/png": ".png",
        "image/gif": ".gif",
        "image/svg+xml": ".svg",
    };
    return mimeToExtMap[mimeType] || "";
};
const parseAssetInfos = (values) => {
    const assetNames = [];
    forEach(values, (value, variant) => {
        if (typeof value !== "string") {
            return;
        }
        const match = value.match(/context\.getAsset\('([^']+)'\)\.getUrl\(\)/);
        if (match) {
            assetNames.push({ variant, assetName: match[1] });
        }
    });
    return assetNames;
};
const downloadAsset = (assetUrl, fileName) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield fetch(assetUrl);
        if (!response.ok) {
            throw new Error(`Failed to fetch asset: ${assetUrl}`);
        }
        const blob = yield response.blob();
        const extension = getExtensionFromMimeType(blob.type);
        const file = new File([blob], `${fileName}${extension}`, {
            type: blob.type,
        });
        return file;
    }
    catch (error) {
        return null;
    }
});
const downloadAssets = (variable, builtVariable) => __awaiter(void 0, void 0, void 0, function* () {
    const sourceAssetInfos = parseAssetInfos(variable.values);
    const assetsToPullDown = [];
    for (const sourceAssetInfo of sourceAssetInfos) {
        const sourceAsset = yield downloadAsset(builtVariable.values[sourceAssetInfo.variant], sourceAssetInfo.assetName);
        if (sourceAsset) {
            assetsToPullDown.push({
                assetName: sourceAssetInfo.assetName,
                file: sourceAsset,
            });
        }
    }
    return assetsToPullDown;
});
const loadVariable = (campaignId, componentId, variableName, store) => __awaiter(void 0, void 0, void 0, function* () {
    yield store.fetchVariable(campaignId, componentId, variableName);
    const variable = store.getVariable(campaignId, componentId, variableName);
    if (!variable) {
        throw new Error(`Variable ${variableName} not found`);
    }
    return variable;
});
const loadBuiltVariable = (componentId, variableName) => __awaiter(void 0, void 0, void 0, function* () {
    const { builtComponentVariable } = useBuiltComponentVariable(componentId, variableName);
    yield until(() => builtComponentVariable.value).not.toBeUndefined();
    if (!builtComponentVariable.value) {
        throw new Error(`Variable ${variableName} not found`);
    }
    return builtComponentVariable.value;
});
const loadBuiltComponent = (componentId) => __awaiter(void 0, void 0, void 0, function* () {
    const { builtComponent, isReady } = useBuiltComponent(componentId);
    yield until(() => isReady.value);
    if (!builtComponent.value) {
        throw new Error(`Component ${componentId} not found`);
    }
    return builtComponent.value;
});
const loadComponentAssets = (campaignId, componentId) => __awaiter(void 0, void 0, void 0, function* () {
    const campaignComponentAssetStore = useCampaignComponentAssetStore();
    yield campaignComponentAssetStore.fetchAssets(campaignId, componentId);
    const localAssets = campaignComponentAssetStore.getAssets(campaignId, componentId);
    return localAssets;
});
const updateComponentAsset = (campaignId, componentId, assetId, file) => __awaiter(void 0, void 0, void 0, function* () {
    const campaignComponentAssetStore = useCampaignComponentAssetStore();
    yield campaignComponentAssetStore.updateAsset(campaignId, componentId, assetId, {}, file);
});
const createComponentAsset = (campaignId, componentId, assetName, file) => __awaiter(void 0, void 0, void 0, function* () {
    const campaignComponentAssetStore = useCampaignComponentAssetStore();
    yield campaignComponentAssetStore.createAsset(campaignId, componentId, {
        name: assetName,
        description: "",
        tags: [],
    }, file);
});
export const isVariableQualifiedForAssetOperation = (variable) => {
    return variable.type === "IMAGE" && variable.source === "INHERITED";
};
export const pullDownSourceVariableImageAssetsAndCalculateVariableValues = (campaignId, componentId, variableName, store) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const builtVariable = yield loadBuiltVariable(componentId, variableName);
        const sourceBuiltComponent = yield loadBuiltComponent(builtVariable.source_component_id);
        const sourceVariable = yield loadVariable(sourceBuiltComponent.campaign_id, sourceBuiltComponent.id, variableName, store);
        const sourceBuiltVariable = yield loadBuiltVariable(sourceBuiltComponent.id, variableName);
        const assetsToPullDown = yield downloadAssets(sourceVariable, sourceBuiltVariable);
        const localComponentAssets = yield loadComponentAssets(campaignId, componentId);
        for (const assetToPullDown of assetsToPullDown) {
            const existingAsset = localComponentAssets.find((asset) => asset.name === assetToPullDown.assetName);
            if (existingAsset) {
                yield updateComponentAsset(campaignId, componentId, existingAsset.id, assetToPullDown.file);
            }
            else {
                yield createComponentAsset(campaignId, componentId, assetToPullDown.assetName, assetToPullDown.file);
            }
        }
        return sourceVariable.values;
    }
    catch (error) {
        console.error(error);
        if (error instanceof Error) {
            faro.pushError(error);
        }
        return {};
    }
});
