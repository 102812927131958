import Numbers from "src/shared/Numbers";
const VALUE_TYPES = {
    USD: "$",
    PERCENT_OFF: "%",
    POINTS: "points",
    AUD: "Au$",
    BRL: "R$",
    CAD: "Can$",
    CNY: "RMB￥",
    EUR: "€",
    GBP: "£",
    INR: decodeURIComponent("\u20B9"),
    JPY: "JP¥",
    KRW: decodeURIComponent("\u20A9"),
    MONTH: "Months",
    MXN: "Mex$",
    NZD: "NZ$",
    TRY: decodeURIComponent("\u20BA"),
    TWD: "NT$",
    HKD: "HK$",
};
const formatValue = (data) => {
    if (data.face_value_algorithm_type === "CASH_BACK") {
        const percentage = parseFloat(data.cash_back_percentage) * 100;
        return percentage ? `${parseFloat(percentage.toFixed(2))}%` : "";
    }
    const value = data.face_value;
    const valueType = data.face_value_type;
    const type = VALUE_TYPES[valueType];
    switch (valueType) {
        case "POINTS":
            return `${Numbers.humanize(value)} ${type}`;
        case "PERCENT_OFF":
            return Numbers.humanize(value) + type;
        default:
            return type + Numbers.humanize(value);
    }
};
class RewardSupplier {
    constructor(rewardSupplierResponse) {
        Object.assign(this, rewardSupplierResponse);
    }
    getIconClass() {
        return "fas fa-wallet";
    }
    getDetailLink() {
        return `/account-rewards`;
    }
    getTypeLink() {
        return `/account-rewards/${this.reward_supplier_type.toLowerCase()}`;
    }
    getTypeDisplayName() {
        if (this.isIntegrationRewardSupplier()) {
            return this.getIntegrationNameFromTags();
        }
        return this.display_type;
    }
    getFormattedValue() {
        return formatValue(this);
    }
    getIntegrationNameFromTags() {
        if (this.isIntegrationRewardSupplier()) {
            const filteredTags = this.tags.filter((tag) => tag !== "internal:integration" &&
                tag !== "internal:reward-supplier");
            return filteredTags.length > 0
                ? (filteredTags[0].split(":") || [])[1] || ""
                : "";
        }
        return "";
    }
    isIntegrationRewardSupplier() {
        return this.tags.includes("internal:integration");
    }
    getComponentId() {
        return this.component_ids[0];
    }
}
export default RewardSupplier;
