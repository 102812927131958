import _ from "underscore";
import { computed } from "vue";
import Session from "Session";
export const HIDDEN_TAGS = [
    "marketing",
    "influencer-v2",
    "international",
    "hidden",
    "internal:.*",
];
export const hasTag = (tags, tag) => {
    return _.contains(tags, tag);
};
export const addTag = (tags, tag) => {
    if (!hasTag(tags, tag)) {
        tags.push(tag);
    }
};
export const removeTag = (tags, tag) => {
    if (hasTag(tags, tag)) {
        tags.splice(tags.indexOf(tag), 1);
    }
};
export const toggleTag = (tags, tag) => {
    if (hasTag(tags, tag)) {
        removeTag(tags, tag);
    }
    else {
        addTag(tags, tag);
    }
};
export const isInternal = (tag) => {
    return _.any(HIDDEN_TAGS, (hidden) => {
        return new RegExp(hidden).test(tag);
    });
};
export const maskTagsBasedOnUserRole = (tags) => {
    const maskedTags = computed({
        get() {
            if (!Session.getInstance().isSuperUser()) {
                return _.filter(tags.value, (tag) => {
                    return !isInternal(tag);
                });
            }
            else {
                return tags.value;
            }
        },
        set(value) {
            tags.value = value;
        },
    });
    return {
        maskedTags,
    };
};
export const havingAnyTags = (item, tags) => {
    if (!tags) {
        return true;
    }
    return _.intersection(item.tags, tags).length > 0;
};
export const havingAllTags = (item, tags) => {
    if (!tags) {
        return true;
    }
    return _.intersection(item.tags, tags).length === tags.length;
};
export const excludeHavingAnyTags = (item, tags) => {
    if (!tags) {
        return true;
    }
    return _.intersection(item.tags, tags).length === 0;
};
export const excludeHavingAllTags = (item, tags) => {
    if (!tags) {
        return true;
    }
    return _.intersection(item.tags, tags).length < tags.length;
};
