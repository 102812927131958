import $ from "jquery";
const getUrl = (campaignId, componentId) => `api:///v2/campaigns/${campaignId}/components/${componentId}/assets`;
const list = (campaignId, componentId) => {
    return $.ajax({
        type: "GET",
        url: getUrl(campaignId, componentId),
    });
};
const get = (campaignId, componentId, assetId) => {
    return $.ajax({
        type: "GET",
        url: `${getUrl(campaignId, componentId)}/${assetId}`,
    });
};
const getContent = (campaignId, componentId, assetId) => {
    return $.ajax({
        type: "GET",
        url: `${getUrl(campaignId, componentId)}/${assetId}/content`,
    });
};
const create = (campaignId, componentId, data) => {
    return $.ajax({
        url: getUrl(campaignId, componentId),
        data,
        cache: false,
        contentType: false,
        processData: false,
        type: "POST",
    });
};
const update = (campaignId, componentId, assetId, data) => {
    return $.ajax({
        url: `${getUrl(campaignId, componentId)}/${assetId}`,
        data,
        cache: false,
        contentType: false,
        processData: false,
        type: "PUT",
    });
};
const deleteAsset = (campaignId, componentId, assetId) => {
    return $.ajax({
        type: "DELETE",
        url: `${getUrl(campaignId, componentId)}/${assetId}`,
    });
};
const listBuilt = (campaignId, componentId) => {
    return $.ajax({
        type: "GET",
        url: `${getUrl(campaignId, componentId)}/built`,
    });
};
const getBuilt = (campaignId, componentId, assetId) => {
    return $.ajax({
        type: "GET",
        url: `${getUrl(campaignId, componentId)}/${assetId}/built`,
    });
};
export default {
    list,
    get,
    getContent,
    create,
    update,
    deleteAsset,
    listBuilt,
    getBuilt,
};
