var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMemoize } from "@vueuse/core";
import TaskQueue from "./TaskQueue";
export const getCampaignOperationQueue = useMemoize((campaignId) => {
    return new TaskQueue();
});
export const doCampaignOperation = (campaignId, operation) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => {
        const queue = getCampaignOperationQueue(campaignId);
        queue.push((done) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                yield operation();
                resolve();
            }
            catch (error) {
                reject(error);
            }
            finally {
                done();
            }
        }));
    });
});
