var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { each } from "lodash";
import { defineStore } from "pinia";
import { toPromise } from "src/shared/api/promise";
import ClientKeyEndpoints from "src/shared/api/security/key/ClientKeyEndpoints";
import { reactive } from "vue";
import ClientKey from "./model/ClientKey";
export const useClientKeyStore = defineStore("clientKeyStore", () => {
    const state = reactive({
        clientKeys: {},
        builtClientKeys: {},
    });
    const setClientKeys = (clientKeyResponses) => {
        each(clientKeyResponses, (clientKeyResponse) => {
            const clientKey = new ClientKey(clientKeyResponse);
            state.clientKeys[clientKey.id] = clientKey;
        });
    };
    const setBuiltClientKeys = (clientKeyResponses) => {
        each(clientKeyResponses, (clientKeyResponse) => {
            const clientKey = new ClientKey(clientKeyResponse);
            state.builtClientKeys[clientKey.id] = clientKey;
        });
    };
    const fetchClientKeys = () => __awaiter(void 0, void 0, void 0, function* () {
        const clientKeyResponses = yield toPromise(ClientKeyEndpoints.listClientKeys());
        setClientKeys(clientKeyResponses);
    });
    const fetchBuiltClientKeys = () => __awaiter(void 0, void 0, void 0, function* () {
        const clientKeyResponses = yield toPromise(ClientKeyEndpoints.listBuilt());
        setBuiltClientKeys(clientKeyResponses);
    });
    const getClientKeys = () => {
        return Object.values(state.clientKeys);
    };
    const getBuiltClientKeys = () => {
        return Object.values(state.builtClientKeys);
    };
    const getBuiltClientKey = (id) => {
        return state.builtClientKeys[id];
    };
    const getClientKey = (id) => {
        return state.clientKeys[id];
    };
    return {
        state,
        fetchClientKeys,
        fetchBuiltClientKeys,
        getClientKeys,
        getClientKey,
        getBuiltClientKeys,
        getBuiltClientKey,
    };
});
