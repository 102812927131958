import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Keys from "src/shared/Keys";
import ExtoleError from "ExtoleError";
import Strings from "src/shared/Strings";
import focusOnErrorFieldService from "src/shared/focus-on-error-field-service";

const FormView = Backbone.View.extend({
    initialize(attributes) {
        const self = this;
        if (attributes.serialize) {
            this.serialize = attributes.serialize;
        }
        if (attributes.submit) {
            this.submit = attributes.submit;
        }
        if (attributes.template) {
            this.template = attributes.template;
        }
        if (attributes.stickyAlertMessageService) {
            this.stickyAlertMessageService =
                attributes.stickyAlertMessageService;
        }
        this.shouldValidate = attributes.shouldValidate;
        this.extension = attributes.extension || {};
        this.excludeErrors = attributes.excludeErrors || [];

        this.listenTo(this.model, "invalid", (model, error) => {
            self.displayErrors(new ExtoleError(error));
        });
        this.listenTo(this.model, "error", (model, response) => {
            self.displayErrors(new ExtoleError(response));
        });
        this.listenTo(this.model, "change", (model) => {
            const changed = model.changed || {};
            _.each(_.keys(changed), (attributeName) => {
                self.clearErrors(attributeName);
            });
        });
    },

    events: {
        "change [name]": "setModelAttribute",
        "keyup [name]": "setModelAttribute",
        submit: "onSubmit",
    },

    render() {
        if (this.template) {
            this.$el.html(
                this.template(_.extend(this.model.toJSON(), this.extension))
            );
        }
        return this;
    },

    setModelAttribute(event) {
        const keyCode = event.keyCode || event.which;
        if (keyCode == Keys.TAB) {
            return;
        }
        const input = event.currentTarget;
        const $input = $(input);
        if ($input.hasClass("js-ignore-field")) {
            return;
        }
        const name = $input.attr("name");
        let value;
        this.clearErrors(name);

        switch (input.type) {
            case "checkbox":
                value = $input.is(":checked");
                break;

            default:
                value = $input.val();
                if ($input.hasClass("js-allow-empty")) {
                    break;
                }
                break;
        }
        if ($input.hasClass("js-is-omissible") && $input.val() === "") {
            value = null;
        }
        this.model.set(name, value, {
            validate: this.shouldValidate,
        });
    },

    applyErrorStyling($el, errorMessage) {
        $el.addClass("error");
        this.$el
            .find(".js-error-message")
            .text(Strings.decodeHtmlEntity(errorMessage))
            .show();
    },

    removeErrorStyling($el) {
        $el.removeClass("error");
        this.$el.find(".js-error-message").empty().fadeOut(200);
    },

    displayErrors(error) {
        const httpErrorCode = error && error.getHttpStatusCode();
        if (_.contains(this.excludeErrors, httpErrorCode)) {
            return;
        }

        const errorMessage = error.getMessage();
        const errorCode = error.getCode();
        const defaultErrorMessage = error.getDefaultMessage();
        const $errorStyledElements =
            this.getErrorStyledElementsByErrorCode(errorCode);
        const $catchAll = this.getErrorStyledElementsByErrorCode("catch_all");

        if (this.stickyAlertMessageService) {
            this.stickyAlertMessageService
                .buildAlertMessage()
                .withMessage(errorMessage)
                .render();
        }

        if ($errorStyledElements.length) {
            this.applyErrorStyling($errorStyledElements, errorMessage);
            focusOnErrorFieldService.focus($errorStyledElements);
        } else {
            this.applyErrorStyling(
                $catchAll,
                errorMessage || defaultErrorMessage
            );
        }
    },

    clearErrors(attributeName) {
        const $errorStyledElements =
            this.getErrorStyledElementsByAttributeName(attributeName);
        const self = this;
        _.each($errorStyledElements, (el) => {
            self.removeErrorStyling($(el));
        });
    },

    clearAllErrors() {
        const self = this;
        _.each($(".js-styled-error"), (el) => {
            self.removeErrorStyling($(el));
        });
    },

    getErrorStyledElementsByAttributeName(attributeName) {
        const selector = `.js-styled-error[name="${attributeName}"]`;
        const $elementsTargetedByAttributeName = this.$(selector);
        if ($elementsTargetedByAttributeName.length === 0) {
            return;
        }

        let $siblings;
        if ($elementsTargetedByAttributeName[0].type === "select") {
            $siblings = $elementsTargetedByAttributeName
                .parent()
                .parent()
                .find(".js-styled-error");
        } else if (
            $elementsTargetedByAttributeName.hasClass("js-token-value")
        ) {
            $siblings = $elementsTargetedByAttributeName
                .parent()
                .find(".js-styled-error");
        } else {
            $siblings =
                $elementsTargetedByAttributeName.siblings(".js-styled-error");
        }

        return $elementsTargetedByAttributeName.add($siblings);
    },

    getErrorStyledElementsByErrorCode(code) {
        const $elementsTargetingThisCode = this.$(
            ".js-styled-error[data-error-codes]"
        ).filter(function () {
            const codes = $(this).data("error-codes") || "";
            return codes.indexOf(code) > -1;
        });

        let $elements = $elementsTargetingThisCode;
        const $elementsTargetingParent = $elementsTargetingThisCode.parent();
        if ($elementsTargetingParent.hasClass("input-wrapper")) {
            $elements = $elements.add(
                $elementsTargetingParent.siblings(".js-styled-error")
            );
        }

        return $elements.add(
            $elementsTargetingThisCode.siblings(".js-styled-error")
        );
    },

    onSubmit(event) {
        event.preventDefault();
        this.clearAllErrors();
        const data = this.serialize();
        let modelCompletelyUpdated = true;
        for (const key in data) {
            if (!key || key === "undefined") {
                continue;
            }
            let value = data[key];
            if (value === "on" || value === "off") {
                value = this.$el.find(`[name='${key}']`).is(":checked");
            }
            if (!this.model.set(key, value, { validate: true })) {
                modelCompletelyUpdated = false;
            }
        }
        if (modelCompletelyUpdated) {
            this.submit();
        }
    },

    submit() {
        const $submitButton = this.$el
            .find('button[type="submit"],.js-submit')
            .first();
        const hasIndicator = $submitButton.hasClass("indicator-button");

        $submitButton.attr("disabled", true);
        if (hasIndicator) {
            $submitButton.addClass("indicator-button--active");
        }
        $.when(this.model.save()).always(() => {
            $submitButton.attr("disabled", false);
            if (hasIndicator) {
                $submitButton.removeClass("indicator-button--active");
            }
        });
    },

    clear() {
        this.$el.trigger("reset");
    },

    clearEvents() {
        this.undelegateEvents();
    },

    serialize() {
        const formData = {};
        const $inputs = this.$("input, select, textarea");
        const self = this;
        _.each($inputs, (input) => {
            const $input = $(input);
            if (
                $input.hasClass("js-ignore-field") ||
                $input.prop("disabled") ||
                $input.closest(".js-is-ignored").length > 0
            ) {
                return;
            }
            const name = $input.attr("name");
            if (!name) {
                return;
            }
            let value = $input.val();
            if (_.isString(value)) {
                value = value.trim();
            }
            if (
                (input.type === "select" || !$input.is(":required")) &&
                value === ""
            ) {
                const parameters = self.model.get("parameters");
                if (parameters) {
                    if (parameters[name]) {
                        if ($input.hasClass("js-allow-undefined")) {
                            delete parameters[name];
                            self.model.set("parameters", parameters);
                            return;
                        }
                    } else {
                        if ($input.hasClass("js-allow-empty")) {
                            parameters[name] = value;
                        } else {
                            delete parameters[name];
                        }
                        self.model.set("parameters", parameters);
                        return;
                    }
                }
                if (!self.model.has(name)) {
                    delete formData[name];
                    return;
                }
            }
            formData[name] = value;
        });
        return formData;
    },
});

FormView.Builder = function () {
    let _element;
    let _model;
    let _template;
    let _serializeMethod;
    let _submitMethod;
    let _extension;
    let _shouldValidate = true;
    let _excludeErrors = [];
    let _stickyAlertMessageService;

    this.withRootElement = function (element) {
        _element = element;
        return this;
    };

    this.withModel = function (model) {
        _model = model;
        return this;
    };

    this.withTemplate = function (template) {
        if (typeof template === "string") {
            _template = _.template(template);
        }
        return this;
    };

    this.withExtendedModel = function (extension) {
        _extension = extension;
        return this;
    };

    this.withSerializeMethod = function (serializeMethod) {
        _serializeMethod = serializeMethod;
        return this;
    };

    this.withSubmitMethod = function (submitMethod) {
        _submitMethod = submitMethod;
        return this;
    };

    this.withStickyAlertMessageService = function (stickyAlertMessageService) {
        _stickyAlertMessageService = stickyAlertMessageService;
        return this;
    };

    this.shouldValidate = function (validate) {
        _shouldValidate = validate;
        return this;
    };

    this.excludeErrors = function (errors) {
        _excludeErrors = errors;
        return this;
    };

    this.render = function () {
        return new FormView({
            el: _element,
            model: _model,
            template: _template,
            serialize: _serializeMethod,
            submit: _submitMethod,
            extension: _extension,
            excludeErrors: _excludeErrors,
            shouldValidate: _shouldValidate,
            stickyAlertMessageService: _stickyAlertMessageService,
        }).render();
    };
};

export default FormView;
