const defaults = {
    STRING: "",
    BOOLEAN: true,
    INTEGER: 0,
    STRING_LIST: [],
    INTEGER_LIST: [],
    JSON: {},
    COLOR: "#FFFFFF",
    IMAGE: null,
    URL: "",
    CLIENT_KEY_FLOW: "",
    CLIENT_KEY: "",
    EXTOLE_CLIENT_KEY: "",
    REWARD_SUPPLIER_ID: "",
    AUDIENCE_ID: [],
    ENUM: "",
};
export const getCampaignComponentVariableDefaultValue = (type) => {
    return defaults[type];
};
