import CustomRewardsRewardSupplier from "./CustomRewardsRewardSupplier";
import ManualCouponsRewardSupplier from "./ManualCouponsRewardSupplier";
import PaypalPayoutsRewardSupplier from "./PaypalPayoutsRewardSupplier";
import SalesforceCouponsRewardSupplier from "./SalesforceCouponsRewardSupplier";
import TangoRewardSupplier from "./TangoRewardSupplier";
export const createRewardSupplierClass = (rewardSupplierResponse) => {
    const { reward_supplier_type } = rewardSupplierResponse;
    const typeToClassMap = {
        MANUAL_COUPON: ManualCouponsRewardSupplier,
        SALESFORCE_COUPON: SalesforceCouponsRewardSupplier,
        TANGO_V2: TangoRewardSupplier,
        CUSTOM_REWARD: CustomRewardsRewardSupplier,
        PAYPAL_PAYOUTS: PaypalPayoutsRewardSupplier,
    };
    const SupplierClass = typeToClassMap[reward_supplier_type];
    if (!SupplierClass) {
        throw new Error(`Unknown reward supplier type: ${reward_supplier_type}`);
    }
    return new SupplierClass(rewardSupplierResponse);
};
