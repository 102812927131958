var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineStore } from "pinia";
import { toPromise } from "src/shared/api/promise";
import AudienceEndpoints from "src/shared/api/audience/AudienceEndpoints";
import { reactive, ref } from "vue";
import Audience from "./model/Audience";
import Person from "../person/model/Person";
import { each, values } from "lodash";
import { audienceArchiveEvent } from "./event-bus";
const convertAudienceFetchParameters = (parameters) => {
    return Object.assign(Object.assign(Object.assign({}, (parameters.include_archived !== undefined && {
        include_archived: String(parameters.include_archived),
    })), (parameters.offset !== undefined && {
        offset: String(parameters.offset),
    })), (parameters.limit !== undefined && {
        limit: String(parameters.limit),
    }));
};
const convertAudienceMembersFetchParameters = (parameters) => {
    return Object.assign(Object.assign(Object.assign({}, (parameters.offset !== undefined && {
        offset: String(parameters.offset),
    })), (parameters.limit !== undefined && {
        limit: String(parameters.limit),
    })), (parameters.email !== undefined &&
        parameters.email !== "" && {
        email: String(parameters.email),
    }));
};
export const useAudienceStore = defineStore("audienceStore", () => {
    const state = reactive({
        audiences: {},
        stats: {},
        members: {},
    });
    const audiencesOrder = ref(new Set());
    const setAudiences = (audiencesResponse) => {
        each(audiencesResponse, (response) => {
            try {
                const audience = new Audience(response);
                state.audiences[audience.id] = audience;
                audiencesOrder.value.add(audience.id);
            }
            catch (error) {
                console.error(error);
            }
        });
    };
    const setMembers = (audienceId, membersResponse) => {
        state.members[audienceId] = {};
        each(membersResponse, (response) => {
            try {
                const person = new Person(response);
                state.members[audienceId][person.person_id] = person;
            }
            catch (error) {
                console.error(error);
            }
        });
    };
    const fetchAudiences = (parameters = {}) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield toPromise(AudienceEndpoints.list(convertAudienceFetchParameters(parameters)));
        setAudiences(response);
    });
    const fetchAudience = (audienceId) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield toPromise(AudienceEndpoints.get(audienceId));
        setAudiences([response]);
    });
    const fetchStats = (audienceId) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield toPromise(AudienceEndpoints.getStats(audienceId));
        state.stats[audienceId] = response.active_members_count;
    });
    const fetchMembers = (audienceId, parameters = {}) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield toPromise(AudienceEndpoints.getMembers(audienceId, convertAudienceMembersFetchParameters(parameters)));
        setMembers(audienceId, response);
    });
    const listAudiences = () => {
        return [...audiencesOrder.value].map((audienceId) => {
            return state.audiences[audienceId];
        });
    };
    const getAudience = (audienceId) => {
        return state.audiences[audienceId];
    };
    const getStats = (audienceId) => {
        return state.stats[audienceId];
    };
    const getMembers = (audienceId) => {
        const members = state.members[audienceId];
        return values(members);
    };
    const createAudience = (data) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield toPromise(AudienceEndpoints.create(data));
            audiencesOrder.value.clear();
            audiencesOrder.value.add(response.id);
            yield fetchAudiences();
            return response;
        }
        catch (error) {
            console.error(error);
        }
    });
    const updateAudience = (audienceId, data) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield toPromise(AudienceEndpoints.update(audienceId, data));
        yield fetchAudience(response.id);
    });
    const archiveAudience = (audienceId) => __awaiter(void 0, void 0, void 0, function* () {
        yield toPromise(AudienceEndpoints.archive(audienceId));
        audiencesOrder.value.delete(audienceId);
        delete state.audiences[audienceId];
        delete state.stats[audienceId];
        delete state.members[audienceId];
        audienceArchiveEvent.emit({ audienceId });
        yield fetchAudiences();
    });
    return {
        state,
        fetchAudiences,
        listAudiences,
        getAudience,
        fetchAudience,
        createAudience,
        updateAudience,
        fetchStats,
        getStats,
        fetchMembers,
        getMembers,
        archiveAudience,
    };
});
