import _ from "underscore";

function isInteger(n) {
    return Number(n) === n && n % 1 === 0;
}

function shortFormatter(number, digits) {
    if (_.isEmpty(number)) {
        return number;
    }

    const si = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (number >= si[i].value) {
            break;
        }
    }

    return (
        (number / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol
    );
}

function humanize(minimumFractionDigits, maximumFractionDigits, number) {
    if (isNaN(number)) {
        return number;
    }
    if (isInteger(number)) {
        return number.toLocaleString(undefined);
    }
    if (typeof number === "string") {
        number = parseFloat(number);
    }
    return parseFloat(number).toLocaleString(undefined, {
        minimumFractionDigits: minimumFractionDigits || 0,
        maximumFractionDigits: maximumFractionDigits || 0,
    });
}

export default {
    humanize: humanize.bind(null, 2, 2),
    humanizeCurrency(number) {
        if (isNaN(number)) {
            return number;
        }
        return humanize(2, 2, number.toString());
    },
    humanizeInputValue: humanize.bind(null, 0, 2),
    format(number) {
        return shortFormatter(number, 1);
    },
};
