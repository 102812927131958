import $ from "jquery";
const PATH = "api:///v6/files";
const list = (data = {}) => {
    return $.ajax({
        type: "GET",
        url: PATH,
        data,
    });
};
const get = (fileAssetId) => {
    return $.ajax({
        type: "GET",
        url: `${PATH}/${fileAssetId}`,
    });
};
const create = (data) => {
    return $.ajax({
        url: PATH,
        data,
        cache: false,
        contentType: false,
        processData: false,
        type: "POST",
    });
};
const update = (fileAssetId, data) => {
    return $.ajax({
        url: `${PATH}/${fileAssetId}`,
        data,
        contentType: "application/json",
        type: "PUT",
    });
};
const archive = (fileAssetId) => {
    return $.ajax({
        type: "DELETE",
        url: `${PATH}/${fileAssetId}`,
    });
};
const download = (fileAssetId) => {
    return $.ajax({
        type: "GET",
        url: `${PATH}/${fileAssetId}/download`,
    });
};
export default {
    list,
    get,
    create,
    update,
    archive,
    download,
};
