import $ from "jquery";
const PATH = "api:///v2/users";
export var UserScopesQueryRequest;
(function (UserScopesQueryRequest) {
    UserScopesQueryRequest["ALL"] = "all";
    UserScopesQueryRequest["ACCOUNT"] = "account";
})(UserScopesQueryRequest || (UserScopesQueryRequest = {}));
const getAll = (scope) => {
    const queryParameters = scope ? `?scope=${scope}` : "";
    return $.ajax({
        method: "GET",
        url: `${PATH}${queryParameters}`,
    });
};
const get = (userId) => $.ajax({
    method: "GET",
    url: `${PATH}/${userId}`,
});
const update = (userId, data) => $.ajax({
    method: "PUT",
    url: `${PATH}/${userId}`,
    contentType: "application/json",
    data: JSON.stringify(data),
});
const create = (data) => $.ajax({
    method: "POST",
    url: PATH,
    contentType: "application/json",
    data: JSON.stringify(data),
});
const archive = (userId) => $.ajax({
    method: "DELETE",
    url: `${PATH}/${userId}`,
});
const resetPassword = (request) => $.ajax({
    method: "POST",
    url: `${PATH}/password-reset`,
    contentType: "application/json",
    data: JSON.stringify(request),
});
const unlock = (userId) => $.ajax({
    method: "POST",
    url: `${PATH}/${userId}/unlock`,
});
export default {
    getAll,
    get,
    update,
    create,
    archive,
    resetPassword,
    unlock,
};
