import BaseAudienceOperationDataSource from "./BaseAudienceOperationDataSource";
import AudienceMember from "./AudienceMember";
class PersonListAudienceOperationDataSource extends BaseAudienceOperationDataSource {
    constructor(dataSource) {
        super(dataSource);
        this.audience_members = dataSource.audience_members.map((member) => new AudienceMember(member));
    }
}
PersonListAudienceOperationDataSource.TYPE = "PERSON_LIST";
export default PersonListAudienceOperationDataSource;
